import React, { useEffect, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { useContext } from "react";
import axios from "axios";

const AddItemColor = ({ previousPage = "none" }) => {
  const { itemId, setItemId } = useContext(GlobalContext);
  const [resListColor, setResListColor] = useState();

  // initAPI
  useEffect(() => {
    const initApiData = async () => {
      try {
        const resListColor = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/item/color`, {
          headers: {
            token: localStorage.getItem("uToken"),
          },
        });
        setResListColor([]);
        setResListColor(resListColor.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    initApiData();
  }, []);
  // endof initAPI

  return (
    <>
      <h2 className="mb-5 text-center">Add Data Warna Item</h2>
      <div className="mb-3 row form-group">
        <form>
          <label htmlFor="colorName" className="col-sm-3 col-form-label form-label">
            Pilih Warna
          </label>
          <div className="col-sm-6">
            {resListColor &&
              resListColor.map((e, i) => {
                return (
                  <>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="checkbox" htmlFor={e.id} value={e.id} />
                      <label className="form-check-label" name={e.id}>
                        {e.name}
                      </label>
                    </div>
                  </>
                );
              })}
          </div>

          <label htmlFor="colorName" className="col-sm-3 col-form-label form-label">
            Tambah Warna
          </label>
          <div className="col-sm-4">
            <input type="text" class="form-control" name="itemName" placeholder="" />
          </div>
        </form>
      </div>
    </>
  );
};

export default AddItemColor;
