import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";

const AddSupplier = () => {
  const { isAddSupplierOpened, setIsAddSupplierOpened } = useContext(GlobalContext);
  const { fetchSupplierStatus, setFetchSupplierStatus } = useContext(GlobalContext);

  const [listBank, setListBank] = useState([]);
  const [bankReq, setBankReq] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const formSupplierRef = useRef(null);

  const handleChangeBank = (e) => {
    setBankReq(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // setErrorMsg("");
    let request = {};
    const form = formSupplierRef.current;
    const data = new FormData(form);
    request["name"] = data.get("supplierName");
    request["address"] = data.get("supplierAddress");
    request["contactPersonName"] = data.get("contactPersonName");
    request["contactPersonTelp"] = data.get("contactPersonPhone");
    request["BankId"] = parseInt(bankReq);
    request["accNo"] = data.get("bankNumber");
    request["accName"] = data.get("bankHolderName");
    request["accBranch"] = data.get("bankBranch");

    // console.log(request);
    onSubmitSupplier(request);
  };

  useEffect(() => {
    const initApiData = async () => {
      try {
        const resListBank = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/purchasing/bank`, {
          headers: {
            token: localStorage.getItem("uToken"),
          },
        });
        setListBank([]);
        setListBank(resListBank.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    initApiData();
  }, []);

  const onSubmitSupplier = async (request) => {
    setSuccessMsg("");
    setErrorMsg("");
    await axios
      .post(`${process.env.REACT_APP_BASE_API_URL}/purchasing/supplier`, request, {
        headers: {
          token: localStorage.getItem("uToken"),
        },
      })
      .then(function (response) {
        // console.log(response.data.msg);
        setSuccessMsg(response.data.msg);
        setFetchSupplierStatus(true);
      })
      .catch(function (error) {
        // console.log(error);
        setErrorMsg(error.response.data.msg);
      });
  };

  return (
    <>
      {isAddSupplierOpened && (
        <div className="card p-3 mb-3 ">
          <h2 className="mb-5 text-center">Tambah Data Supplier</h2>
          <form ref={formSupplierRef} onSubmit={handleSubmit}>
            <div className="mb-3 row form-group">
              <label for="supplierName" className="col-sm-3 col-form-label form-label">
                Nama Supplier
              </label>
              <div className="col-sm-4">
                <input type="text" className="form-control" name="supplierName" placeholder="Contoh: PT XYZ" />
              </div>
            </div>

            <div className="mb-3 row form-group">
              <label for="supplierAddress" className="col-sm-3 col-form-label form-label">
                Alamat Supplier
              </label>
              <div className="col-sm-8">
                <input type="text" className="form-control" name="supplierAddress" placeholder="Contoh: Jl. Daan Mogot KM100 no. 72" />
              </div>
            </div>

            <div className="mb-3 row form-group">
              <label for="contactPersonName" className="col-sm-3 col-form-label form-label">
                Contact Person
              </label>
              <div className="col-sm-4">
                <input type="text" className="form-control" name="contactPersonName" placeholder="Contoh: John Doe" />
              </div>
            </div>

            <div className="mb-3 row form-group">
              <label for="contactPersonPhone" className="col-sm-3 col-form-label form-label">
                No Telepon Contact Person
              </label>
              <div className="col-sm-4">
                <input type="text" className="form-control" name="contactPersonPhone" placeholder="Contoh: 08334567890" />
              </div>
            </div>

            <div className="mb-3 row form-group">
              <label for="bankName" className="col-sm-3 col-form-label form-label">
                Bank
              </label>
              <div className="col-sm-3">
                <select className="form-select" name="bankName" onChange={handleChangeBank}>
                  {/* <option defaultValue="Pilih salah satu">Pilih salah satu</option> */}
                  {listBank.length > 0
                    ? listBank.map((e) => {
                        return (
                          <option value={e.id} key={e.id}>
                            {e.name}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>

              <div className="col-sm-1"></div>

              <label for="bankBranch" className="col-sm-2 col-form-label form-label">
                Cabang
              </label>
              <div className="col-sm-3">
                <input type="text" className="form-control" name="bankBranch" placeholder="Contoh: KCP Kelapa Dua" />
              </div>
            </div>

            <div className="mb-3 row form-group">
              <label for="bankHolderName" className="col-sm-3 col-form-label form-label">
                Nama Pemilik Rekening
              </label>
              <div className="col-sm-3">
                <input type="text" className="form-control" name="bankHolderName" placeholder="Contoh: John Du" />
              </div>

              <div className="col-sm-1"></div>

              <label for="bankNumber" className="col-sm-2 col-form-label form-label">
                No Rekening
              </label>
              <div className="col-sm-3">
                <input type="text" className="form-control" name="bankNumber" placeholder="Contoh: 5270128371" />
              </div>
            </div>

            <div className="text-center">
              {successMsg ? (
                <>
                  <p className="text-success">
                    Supplier berhasil ditambahkan, klik <b>Tutup</b> untuk menutup form ini
                  </p>{" "}
                  <button type="button" className="btn btn-primary" onClick={() => setIsAddSupplierOpened(false)}>
                    Tutup
                  </button>
                </>
              ) : (
                <>
                  <p className="text-danger">{errorMsg}</p>{" "}
                  <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                    Tambah Supplier
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default AddSupplier;
