import React, { useEffect, useMemo, useState } from "react";
import SideNavBar from "../SideNavBar/SideNavBar";
import "./Pembelian.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Table from "../Table/Table";
import iconDetail from "../../assets/img/ic_detail.png";
import iconEdit from "../../assets/img/ic_edit.png";
import iconPay from "../../assets/img/ic_pay.png";
import iconDelete from "../../assets/img/ic_delete.png";
import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { Modal, Button } from "react-bootstrap";
import Helper from "../../util/Helper";

const Pembelian = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const listMenu = localStorage.getItem("listMenu");

  const { setCurrentPurchasingId } = useContext(GlobalContext);

  const [purchasingData, setPurchasingData] = useState([]);
  const [errorGetPurchasing, setErrorGetPurchasing] = useState("");

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const [deleteId, setDeleteId] = useState();
  const [fetchStatus, setFetchStatus] = useState(true);

  const handleCancel = () => setShowModalDelete(false);

  // initAPI
  useEffect(() => {
    if (fetchStatus === true) {
      axios
        .get(`${process.env.REACT_APP_BASE_API_URL}/purchasing`, {
          headers: {
            token: localStorage.getItem("uToken"),
          },
        })
        .then((res) => {
          setPurchasingData([]);
          setPurchasingData(res.data.data);
        })
        .catch((error) => {
          setErrorGetPurchasing("");
          setErrorGetPurchasing(error);
        });
      setFetchStatus(false);
    }
  }, [fetchStatus, setFetchStatus]);

  const deleteItem = async (itemId) => {
    try {
      const resDelete = await axios.delete(`${process.env.REACT_APP_BASE_API_URL}/purchasing/${itemId}`, {
        headers: {
          token: localStorage.getItem("uToken"),
        },
      });

      setFetchStatus(true);
      console.log(resDelete);
    } catch (error) {
      console.log(error);
    }
  };
  // endof initAPI

  // init handler Click
  const handleDetail = (event) => {
    let idData = parseInt(event.currentTarget.value);
    setCurrentPurchasingId(idData);
    navigate(`detail/${idData}`);
  };

  const handleEdit = (event) => {
    let idData = parseInt(event.currentTarget.value);
    setCurrentPurchasingId(idData);
    navigate(`edit/${idData}`);
  };

  const handleDeleteModal = (event) => {
    event.preventDefault();

    let idData = parseInt(event.currentTarget.value);
    // console.log(idData);
    // const index = purchasingData.findIndex((e, i) => {
    //   if (e.id == idData) {
    //     return true;
    //   }
    // });
    // console.log(purchasingData);

    setDeleteId(idData);
    setShowModalDelete(true);
    // if (index >= 0)
  };

  const handleDelete = () => {
    deleteItem(deleteId);
    setShowModalDelete(false);
  };
  // endof handler click

  // init Header Table
  const columns = useMemo(
    () => [
      {
        Header: "Tanggal Invoice",
        accessor: "invoiceDate",
        Cell: ({ cell: { value } }) => {
          const options = { year: "numeric", month: "long", day: "numeric" };
          return new Date(value).toLocaleDateString("id-ID", options);
        },
      },
      {
        Header: "No Invoice",
        accessor: "invoiceNo",
      },
      {
        Header: "Supplier Name",
        accessor: "Supplier.name",
      },
      {
        Header: "Cara Bayar",
        accessor: "Payment.name",
      },
      {
        Header: "Tenor",
        accessor: "termsOfPayment",
      },
      {
        Header: " ",
        accessor: "taxable",
        Cell: ({ cell: { value } }) => {
          if (value) {
            return "Ya";
          } else {
            return "Tidak";
          }
        },
      },
      {
        Header: "Total",
        accessor: "total",
        Cell: ({ cell: { value } }) => {
          const formatter = new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
          });

          return formatter.format(value);
        },
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ cell: { value } }) => {
          return (
            <div className="text-center">
              <button title="Detail" type="button" className="btn mx-1 p-0" onClick={handleDetail} value={value}>
                <img width={24} height={24} src={iconDetail} alt="Detail"></img>
              </button>
              <button title="Ubah" type="button" className="btn mx-1 p-0" value={value} onClick={handleEdit}>
                <img width={24} height={24} src={iconEdit} alt="Ubah"></img>
              </button>
              <button title="Pembayaran" type="button" className="btn mx-1 p-0" value={value} onClick={() => {}}>
                <img width={24} height={24} src={iconPay} alt="Pembayaran"></img>
              </button>
              <button title="Hapus" type="button" className="btn mx-1 p-0" value={value} onClick={handleDeleteModal}>
                <img width={24} height={24} src={iconDelete} alt="Hapus"></img>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );
  // endof init Header Table

  return (
    <>
      <div id="app" style={({ height: "100vh" }, { display: "flex", flexDirection: "row" })}>
        <SideNavBar />
        <main className="card mt-1 m-2 shadow " style={{ flex: 1 }}>
          <h2 className="mb-3 text-center">Data Pembelian</h2>
          {location.state.msg != null ? <p className="p-3">{location.state.msg}</p> : ""}
          {errorGetPurchasing && <p className="p-3 text-danger">{errorGetPurchasing}</p>}
          {purchasingData && (
            <>
              <Table columns={columns} data={purchasingData} />
            </>
          )}

          <Modal show={showModalDelete} onHide={handleCancel}>
            <Modal.Header closeButton>
              <Modal.Title>Perhatian!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Apakah anda yakin untuk menghapus data berikut ini?
              {/* <p className="mt-3 mb-0">Tanggal Invoice : {deleteIndex && Helper.convertShortDate(purchasingData[deleteIndex]["invoiceDate"])}</p>
              <p className="mb-0">Nomor Invoice : {deleteIndex ? purchasingData[deleteIndex]["invoiceNo"] : "Tidak Ada"}</p>
              <p className="mb-0">Supplier : {deleteIndex && purchasingData[deleteIndex]["Supplier"]["name"]}</p>
              <p className="mb-0">Cara Bayar : {deleteIndex && purchasingData[deleteIndex]["Payment"]["name"]}</p>
              <p className="mb-0">Tempo : {deleteIndex && purchasingData[deleteIndex]["Supplier"]["name"]}</p>
              <p>{console.log(deleteIndex)}</p> */}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCancel}>
                Tidak
              </Button>
              <Button variant="danger" onClick={handleDelete}>
                Hapus Data
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="table"></div>
          <div className="mt-1 text-center ">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                navigate("/pembelian/add", {
                  state: {
                    listMenu: listMenu,
                  },
                });
              }}
            >
              Tambah Data
            </button>
          </div>
        </main>
      </div>
    </>
  );
};

export default Pembelian;
