import { useState } from "react";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
import { Row, Col, Button, Input, CustomInput } from "reactstrap";

export default function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const [filterInput, setFilterInput] = useState("");

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter("Supplier.name", value);
    setFilterInput(value);
  };

  return (
    <div className=" p-3">
      <input value={filterInput} onChange={handleFilterChange} placeholder={"Cari nama supplier"} type="text" className="form-control" />
      <table {...getTableProps()} className="table table-striped border table-bordered mt-2">
        <thead className="border ">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  scope="col"
                  className={column.isSorted ? (column.isSortedDesc ? "text-center sort-desc" : "text-center sort-asc") : "text-center"}
                  style={{ backgroundColor: "#83c5be" }}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <input
                          draggable="false"
                          width={15}
                          height={15}
                          type="image"
                          className="ml-2"
                          alt="🔽"
                          src="https://s.w.org/images/core/emoji/14.0.0/svg/1f53d.svg"
                        />
                      ) : (
                        <input
                          draggable="false"
                          width={15}
                          height={15}
                          type="image"
                          className="ml-2"
                          alt="🔼"
                          src="https://s.w.org/images/core/emoji/14.0.0/svg/1f53c.svg"
                        />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Row style={{ maxWidth: 1000, margin: "0 auto", textAlign: "center" }}>
        <Col md={3}>
          <Button color="primary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </Button>
          <Button color="primary" onClick={previousPage} disabled={!canPreviousPage}>
            {"<"}
          </Button>
        </Col>
        <Col md={2} style={{ marginTop: 7 }}>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col md={2}>
          <Input type="number" min={1} style={{ width: 70 }} max={pageOptions.length} defaultValue={pageIndex + 1} onChange={onChangeInInput} />
        </Col>
        <Col md={2}>
          <Input type="select" value={pageSize} onChange={onChangeInSelect}>
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Input>
        </Col>
        <Col md={3}>
          <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
            {">"}
          </Button>
          <Button color="primary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {">>"}
          </Button>
        </Col>
      </Row>{" "}
    </div>
  );
}
