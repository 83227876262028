import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./css/main.css";
import "./css/util.css";
import "../../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "../../assets/vendor/animate/animate.css";
import "../../assets/vendor/css-hamburgers/hamburgers.min.css";
import "../../assets/vendor/select2/select2.min.css";
import imgLogin from "../Login/images/img-01.png";
import axios from "axios";
import "bootstrap";

const Login = () => {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMsg("");
    const data = new FormData(event.currentTarget);
    const username = data.get("username");
    const password = data.get("pass");

    if (username.length < 3) {
      setErrorMsg("Username minimal 3 karakter");
    } else if (password.length < 6) {
      setErrorMsg("Password minimal 6 karakter");
    } else {
      onLogin(username, password);
    }
  };

  const onLogin = async (username, password) => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_API_URL}/user/login`,
        {
          username: username,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        localStorage.setItem("uToken", response.data.token);
        console.log(response.data.data);
        var listMenu = [];
        var listMaster = [];
        response.data.data.forEach((element) => {
          listMenu.push(element["modul"]);
        });
        response.data.master.forEach((element) => {
          listMaster.push(element["name"]);
        });
        localStorage.removeItem("listMenu");
        localStorage.removeItem("listMaster");
        localStorage.setItem("listMenu", listMenu);
        localStorage.setItem("listMaster", listMaster);
        navigate("/dashboard", {
          state: {
            listMenu: listMenu,
            listMaster: listMaster,
          },
        });
      })
      .catch(function (error) {
        console.log(error);
        setErrorMsg(error.response.data.msg);
      });
  };

  return (
    <>
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <div className="login100-pic js-tilt" data-tilt>
              <img src={imgLogin} alt="IMG" />
            </div>

            <form className="login100-form validate-form" onSubmit={handleSubmit}>
              <span className="login100-form-title">Member Login</span>

              <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                <input className="input100" type="text" name="username" placeholder="Username" />
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                  <i className="fa fa-solid fa-user"></i>
                </span>
              </div>

              <div className="wrap-input100 validate-input" data-validate="Password is required">
                <input className="input100" type="password" name="pass" placeholder="Password" />
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                  <i className="fa fa-lock" aria-hidden="true"></i>
                </span>
              </div>

              <div className="container-login100-form-btn">
                <button className="login100-form-btn" onClick={onLogin}>
                  Login
                </button>
              </div>

              <div className="text-center text-red">{errorMsg !== "" ? errorMsg : ""}</div>

              {/* <div className="text-center p-t-12">
                                <span className="txt1">
                                    Forgot
                                </span>
                                <a className="txt2" href="#">
                                    Username / Password?
                                </a>
                            </div> */}

              <div className="text-center p-t-136">
                {/* <a className="txt2" href="#">
                                    Create your Account
                                    <i className="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
                                </a> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
