class Helper {
  static convertRupiah = (nominal) => {
    const formatter = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(nominal);
  };

  static convertShortDate = (date) => {
    const initialDate = new Date(date);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const resultDate = initialDate.toLocaleDateString("id-ID", options);
    return resultDate.split("/").reverse().join("-");
  };
}
export default Helper;
