import React from "react";
import SideNavBar from "../../SideNavBar/SideNavBar";

const Retur = () => {
  return (
    <>
      <div id="app" style={({ height: "100vh" }, { display: "flex", flexDirection: "row" })}>
        <SideNavBar />
        <main>Return</main>
      </div>
    </>
  );
};

export default Retur;
