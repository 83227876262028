import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Dashboard from "./components/Dashboard/Dashboard";
import { ProSidebarProvider } from "react-pro-sidebar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Pembelian from "./components/Pembelian/Pembelian";
import AddPembelian from "./components/Pembelian/Add/AddPembelian";
import { GlobalProvider } from "./context/GlobalContext";
import Retur from "./components/Pembelian/Retur/Retur";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GlobalProvider>
      <ProSidebarProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="pembelian" element={<Pembelian />} />
            <Route path="pembelian/add" element={<AddPembelian />} />
            <Route path="pembelian/detail/:IdData" element={<AddPembelian status="detail" />} />
            <Route path="pembelian/edit/:IdData" element={<AddPembelian status="edit" />} />
            <Route path="pembelian/detail/:IdData/retur/:ItemId" element={<Retur />} />
          </Routes>
        </BrowserRouter>
      </ProSidebarProvider>
    </GlobalProvider>
  </React.StrictMode>
);
// export default function App() {
//   return (
//     <BrowserRouter>

//     </BrowserRouter>
//   );
// }

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <App />
// );
