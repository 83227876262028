import React, { createContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalProvider = (props) => {
  const [itemId, setItemId] = useState(0);
  const [isAddSupplierOpened, setIsAddSupplierOpened] = useState(false);
  const [currentPurchasingId, setCurrentPurchasingId] = useState(-1);
  const [fetchSupplierStatus, setFetchSupplierStatus] = useState(true);

  return (
    <GlobalContext.Provider
      value={{
        itemId,
        setItemId,
        isAddSupplierOpened,
        setIsAddSupplierOpened,
        currentPurchasingId,
        setCurrentPurchasingId,
        fetchSupplierStatus,
        setFetchSupplierStatus,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
