import React from "react";
import SideNavBar from "../SideNavBar/SideNavBar";

const Dashboard = () => {
  console.log(localStorage.getItem("uToken"));
  return (
    <>
      <div id="app" style={({ height: "100vh" }, { display: "flex" })}>
        <SideNavBar />
        <main>
          <div>Dashboard</div>
        </main>
      </div>
    </>
  );
};

export default Dashboard;
