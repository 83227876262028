import React, { useEffect, useRef, useState } from "react";
import SideNavBar from "../../SideNavBar/SideNavBar";
import axios from "axios";
import AddSupplier from "../../Supplier/Add/AddSupplier";
import AddItem from "../../Item/Add/AddItem";
import { GlobalContext } from "../../../context/GlobalContext";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Helper from "../../../util/Helper";
import iconReturn from "../../../assets/img/ic_return.png";

const AddPembelian = ({ status }) => {
  let { IdData } = useParams();

  const { isAddSupplierOpened, setIsAddSupplierOpened } = useContext(GlobalContext);
  const { fetchSupplierStatus, setFetchSupplierStatus } = useContext(GlobalContext);

  const [paymentMethod, setPaymentMethod] = useState([]);
  const [listItem, setListItem] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [supplierNameReq, setsupplierNameReq] = useState();
  const [paymentMethodReq, setPaymentMethodReq] = useState();
  // const [itemsColor, setItemsColor] = useState([]);
  const [taxReq, setTaxReq] = useState(false);
  const [isAddItemData, setisAddItemData] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [listItemsColor, setListItemsColor] = useState([[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
  const formRef = useRef(null);
  const [input, setInput] = useState({
    invoiceNumber: "",
    invoiceDate: "",
    supplierId: "",
    paymentMethod: "",
    termsOfPayment: "",
    tax: "",
  });

  const navigate = useNavigate();

  const handleChangeSupplier = (e) => {
    setsupplierNameReq(e.target.value);
    const inputSupplier = { ...input, supplierId: e.target.value };
    setInput(inputSupplier);
  };

  const handleChangePaymentMethod = (e) => {
    setPaymentMethodReq(e.target.value);
    const inputPayment = { ...input, paymentMethod: e.target.value };
    setInput(inputPayment);
  };

  const handleChangeTax = (e) => {
    const taxChecked = e.target.checked;
    setTaxReq(taxChecked);
    const inputTax = { ...input, tax: taxChecked };
    setInput(inputTax);
  };

  // region handling multiple input

  const [inputList, setinputList] = useState([{ itemName: "", itemColorId: 0, roll: 0, qty: 0.0, price: 0.0, total: 0.01 }]);

  const handleinputchange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];

    list[index][name] = value;
    setinputList(list);

    if (name === "itemName") {
      let itemId = parseInt(value);
      getItemColor(itemId, index);
    }
  };

  const handleremove = (index) => {
    const listRemove = [...inputList];
    if (listRemove[index + 1]) {
      listRemove.splice(index, 1);
      setinputList(listRemove);
      getItemColor(listRemove[index].itemName, index);
    } else {
      listRemove.splice(index, 1);
      setinputList(listRemove);
    }
  };

  const handleaddclick = () => {
    setinputList([...inputList, { itemName: "", itemColorId: 0, roll: 0, qty: 0.0, price: 0.0, total: 0.01 }]);
  };

  // end region

  useEffect(
    () => {
      setFetchSupplierStatus(true);
    },
    { setFetchSupplierStatus }
  );

  useEffect(() => {
    if (IdData !== undefined) {
      axios
        .get(`${process.env.REACT_APP_BASE_API_URL}/purchasing/${IdData}`, {
          headers: {
            token: localStorage.getItem("uToken"),
          },
        })
        .then((res) => {
          let data = res.data.data;
          let invoiceDate = new Date(data.invoiceDate);
          invoiceDate = Helper.convertShortDate(invoiceDate);
          setInput({
            invoiceNumber: data.invoiceNo,
            invoiceDate: invoiceDate,
            supplierId: data.SupplierId,
            paymentMethod: data.PaymentId,
            termsOfPayment: data.termsOfPayment,
            tax: data.taxable,
          });
          let list = [];

          data.ItemPurchasings.forEach((e, i) => {
            let dataItem = {};
            dataItem["itemId"] = e.id;
            dataItem["itemName"] = e.ItemColor.ItemId;
            dataItem["itemColorId"] = e.ItemColorId;
            dataItem["roll"] = e.roll;
            dataItem["qty"] = e.qty;
            dataItem["price"] = e.price;
            dataItem["total"] = e.total;
            list.push(dataItem);
          });
          setinputList(list);

          list.forEach((e, i) => {
            getItemColor(e.itemName, i);
          });
        });
    }

    const initApiData = async () => {
      try {
        const resPaymentMethod = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/purchasing/payment`, {
          headers: {
            token: localStorage.getItem("uToken"),
          },
        });
        setPaymentMethod([]);
        setPaymentMethod(resPaymentMethod.data.data);
      } catch (error) {
        console.log(error);
      }

      if (fetchSupplierStatus === true) {
        axios
          .get(`${process.env.REACT_APP_BASE_API_URL}/purchasing/supplier`, {
            headers: {
              token: localStorage.getItem("uToken"),
            },
          })
          .then((res) => {
            setSupplier([]);
            setSupplier(res.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
        setFetchSupplierStatus(false);
      }

      // try {
      //   const resSupplier = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/purchasing/supplier`, {
      //     headers: {
      //       token: localStorage.getItem("uToken"),
      //     },
      //   });
      //   setSupplier([]);
      //   setSupplier(resSupplier.data.data);
      // } catch (error) {
      //   console.log(error);
      // }

      try {
        const resItems = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/item`, {
          headers: {
            token: localStorage.getItem("uToken"),
          },
        });
        setListItem([]);
        setListItem(resItems.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    initApiData();
  }, [IdData, fetchSupplierStatus, setFetchSupplierStatus]);

  const listColor = [...listItemsColor];

  const getItemColor = async (itemId, index) => {
    try {
      const resItemColor = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/item/colorItem/${itemId}`, {
        headers: {
          token: localStorage.getItem("uToken"),
        },
      });

      listColor.splice(index, 1, resItemColor.data.data);
      setListItemsColor(listColor);
      // console.log(listColor, index);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMsg("");
    let request = {};
    const form = formRef.current;
    const data = new FormData(form);
    request["invoiceNumber"] = data.get("invoiceNumber");
    request["invoiceDate"] = data.get("invoiceDate").replaceAll("-", "/");
    request["supplierId"] = input.supplierId;
    request["paymentMethod"] = input.paymentMethod;
    request["termsOfPayment"] = parseInt(data.get("termsOfPayment"));
    request["tax"] = taxReq;
    let items = [];
    inputList.forEach((e, i) => {
      let item = {};
      if (status === "edit" && inputList[i].itemId) {
        item["id"] = parseInt(inputList[i].itemId);
      }
      item["itemColorId"] = parseInt(inputList[i].itemColorId);
      item["roll"] = parseFloat(inputList[i].roll);
      item["qty"] = parseFloat(inputList[i].qty);
      item["price"] = parseFloat(inputList[i].price);
      item["total"] = Number(parseFloat(inputList[i].qty) * parseFloat(inputList[i].price)).toFixed(2);
      items.push(item);
    });
    request["items"] = items;
    if (!request["invoiceDate"]) {
      setErrorMsg("Tanggal Invoice wajib diisi");
    } else if (!request["supplierId"]) {
      setErrorMsg("Nama Supplier wajib dipilih");
    } else if (!request["paymentMethod"]) {
      setErrorMsg("Cara Pembayaran wajib dipilih");
    } else {
      // console.log(request);
      if (status === "edit") {
        console.log(request);
        onEditPurchase(request);
      } else {
        onSubmitPurchase(request);
      }
    }
  };

  const onSubmitPurchase = async (request) => {
    await axios
      .post(`${process.env.REACT_APP_BASE_API_URL}/purchasing`, request, {
        headers: {
          token: localStorage.getItem("uToken"),
        },
      })
      .then(function (response) {
        navigate("/pembelian", {
          state: {
            msg: "Data Pembelian Berhasil Dibuat",
          },
        });
        // console.log(response.data.msg);
      })
      .catch(function (error) {
        // console.log(error);
        setErrorMsg(error.response.data.msg);
      });
  };

  const onEditPurchase = async (request) => {
    await axios
      .put(`${process.env.REACT_APP_BASE_API_URL}/purchasing/${IdData}`, request, {
        headers: {
          token: localStorage.getItem("uToken"),
        },
      })
      .then(function (response) {
        navigate("/pembelian", {
          state: {
            msg: "Data Pembelian Berhasil Diubah",
          },
        });
        // console.log(response.data.msg);
      })
      .catch(function (error) {
        // console.log(error);
        setErrorMsg(error.response.data.msg);
      });
  };

  return (
    <>
      <div id="app" style={({ height: "100vh" }, { display: "flex", flexDirection: "row" })}>
        <SideNavBar />
        <main className="card mt-1 m-2 shadow " style={{ flex: 1 }}>
          <h2 className="mb-5 text-center">
            {status === "detail" || status === "edit" ? (status === "detail" ? "Detail Pembelian" : "Edit Data Pembelian") : "Tambah Data Pembelian"}
          </h2>
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="mb-3 row form-group">
              <label htmlFor="invoiceNumber" className="col-sm-2 col-form-label form-label">
                Nomor Invoice
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  name="invoiceNumber"
                  defaultValue={input.invoiceNumber}
                  disabled={status === "detail" ? true : false}
                />
              </div>
            </div>

            <div className="mb-3 row form-group">
              <label htmlFor="invoiceDate" className="col-sm-2 col-form-label form-label">
                Tanggal Invoice <span className="text-danger">*</span>
              </label>
              <div className="col-sm-8">
                <input
                  type="date"
                  className="form-control"
                  name="invoiceDate"
                  placeholder=""
                  defaultValue={input.invoiceDate}
                  disabled={status === "detail" ? true : false}
                />
              </div>
            </div>

            <div className="mb-3 row form-group">
              <label htmlFor="supplierName" className="col-sm-2 col-form-label form-label">
                Nama Supplier <span className="text-danger">*</span>
              </label>
              <div className="col-sm-8">
                <select
                  className="form-select"
                  name="supplierName"
                  onChange={handleChangeSupplier}
                  value={input.supplierId}
                  disabled={status === "detail" ? true : false}
                >
                  <option defaultValue="Pilih salah satu">Pilih salah satu</option>
                  {supplier.length > 0
                    ? supplier.map((e) => {
                        return (
                          <option value={e.id} key={e.id}>
                            {e.name}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>

              {status === "detail" ? (
                ""
              ) : (
                <div className="col-sm-2">
                  <button
                    type="button"
                    className="btn btn-info text-white"
                    onClick={() => {
                      setIsAddSupplierOpened(!isAddSupplierOpened);
                    }}
                  >
                    Tambah Supplier
                  </button>
                </div>
              )}
            </div>

            {isAddSupplierOpened ? <AddSupplier /> : ""}

            <div className="mb-3 row form-group">
              <label htmlFor="paymentMethod" className="col-sm-2 col-form-label form-label">
                Cara Pembayaran <span className="text-danger">*</span>
              </label>
              <div className="col-sm-8">
                <select
                  className="form-select"
                  name="paymentMethod"
                  onChange={handleChangePaymentMethod}
                  value={input.paymentMethod}
                  disabled={status === "detail" ? true : false}
                >
                  <option defaultValue="Pilih salah satu">Pilih salah satu</option>
                  {paymentMethod.length > 0
                    ? paymentMethod.map((e) => {
                        return (
                          <option value={e.id} key={e.id}>
                            {e.name}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>
            </div>

            <div className="mb-3 row form-group">
              <label htmlFor="termsOfPayment" className="col-sm-2 col-form-label form-label">
                Tenor
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control dropdown-toggle"
                  name="termsOfPayment"
                  defaultValue={input.termsOfPayment}
                  disabled={status === "detail" ? true : false}
                />
              </div>
            </div>

            <div className="mb-3 row form-group">
              <div className="col-sm-2"></div>

              <div className="mt-1 col-sm-8 form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="tax"
                  checked={input.tax}
                  onChange={handleChangeTax}
                  disabled={status === "detail" ? true : false}
                />
                <label className="form-check-label" htmlFor="tax">
                  Ya
                </label>
              </div>
            </div>

            <h4>Item Pembelian</h4>

            <div className="card p-3">
              {inputList.map((x, i) => {
                return (
                  <div className="row mb-2">
                    <div className="form-group col">
                      <label htmlFor="itemName">Nama Item</label>
                      <select
                        className="form-select"
                        name="itemName"
                        onChange={(e) => handleinputchange(e, i)}
                        value={inputList[i].itemName}
                        disabled={status === "detail" ? true : false}
                      >
                        <option defaultValue="Pilih salah satu">Pilih salah satu</option>
                        {listItem.length > 0
                          ? listItem.map((e) => {
                              return (
                                <option value={e.id} key={e.id}>
                                  {e.name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </div>

                    <div className="form-group col">
                      <label htmlFor="itemColorId">Warna Item</label>
                      <select
                        className="form-select"
                        name="itemColorId"
                        onChange={(e) => handleinputchange(e, i)}
                        value={inputList[i].itemColorId}
                        disabled={status === "detail" ? true : false}
                      >
                        <option defaultValue="Pilih salah satu">Pilih salah satu</option>
                        {listItemsColor[inputList[i].itemName] != null
                          ? listItemsColor[i].map((e) => {
                              return (
                                <option value={e.id} key={e.id}>
                                  {e.Color.name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </div>

                    <div className="form-group col">
                      <label htmlFor="roll">Roll</label>
                      <input
                        type="number"
                        className="form-control"
                        name="roll"
                        onChange={(e) => handleinputchange(e, i)}
                        value={inputList[i].roll}
                        disabled={status === "detail" ? true : false}
                      />
                    </div>

                    <div className="form-group col">
                      <label htmlFor="qty">Qty</label>
                      <input
                        type="number"
                        className="form-control"
                        name="qty"
                        onChange={(e) => handleinputchange(e, i)}
                        value={inputList[i].qty}
                        disabled={status === "detail" ? true : false}
                      />
                    </div>

                    <div className="form-group col">
                      <label htmlFor="price">Price</label>
                      <input
                        type="number"
                        className="form-control"
                        name="price"
                        onChange={(e) => handleinputchange(e, i)}
                        value={inputList[i].price}
                        disabled={status === "detail" ? true : false}
                      />
                    </div>

                    <div className="form-group col">
                      <label htmlFor="total">Total</label>
                      <input
                        type="text"
                        className="form-control"
                        name="total"
                        onChange={(e) => handleinputchange(e, i)}
                        value={Helper.convertRupiah(Number(parseFloat(inputList[i].qty) * parseFloat(inputList[i].price)).toFixed(2))}
                        disabled
                      />
                    </div>

                    {/* {console.log("get data", inputList)} */}
                    {status === "detail" ? (
                      <div className="form-group col">
                        <button
                          title="Retur"
                          type="button"
                          className="btn btn-warning mx-1 mt-4"
                          value={inputList[i].itemId}
                          onClick={() => {
                            navigate(`retur/${inputList[i].itemId}`);
                          }}
                        >
                          Retur
                        </button>
                      </div>
                    ) : (
                      <div className="form-group col">
                        {inputList.length !== 1 && (
                          <button
                            className="btn btn-danger mx-1 mt-4"
                            onClick={(e) => {
                              handleremove(i);
                              e.preventDefault();
                            }}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}

              {isAddItemData ? <AddItem /> : ""}

              {status === "detail" ? (
                ""
              ) : (
                <div className="row mt-2 d-flex justify-content-center">
                  <div className="col-sm-3">
                    <button
                      type="button"
                      className="btn btn-info text-white"
                      onClick={() => {
                        setisAddItemData(!isAddItemData);
                      }}
                    >
                      Tambah Data Item
                    </button>
                  </div>

                  <div className="col-sm-3">
                    <button type="button" className="btn btn-primary " onClick={handleaddclick}>
                      Tambah Pembelian Barang
                    </button>
                  </div>
                </div>
              )}
            </div>

            {status === "detail" ? (
              ""
            ) : (
              <div className="text-center mt-5">
                {errorMsg && <p className="text-danger text-center">{errorMsg}</p>}

                <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                  Tambah Data
                </button>
              </div>
            )}
          </form>
        </main>
      </div>
    </>
  );
};

export default AddPembelian;
