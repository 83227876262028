import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import PointOfSaleOutlinedIcon from "@mui/icons-material/PointOfSaleOutlined";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { Sidebar, Menu, MenuItem, useProSidebar, SubMenu, menuClasses } from "react-pro-sidebar";
import { LogoutOutlined } from "@mui/icons-material";

const SideNavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { collapseSidebar } = useProSidebar();
  const tempListMenu = localStorage.getItem("listMenu");
  const tempListMaster = localStorage.getItem("listMaster");
  const userMenu = tempListMenu.split(",");
  const userMenuMaster = tempListMaster.split(",");
  const menuListRender = [];
  const masterListRender = [];

  userMenu.forEach((element) => {
    if (element === "USER") {
      menuListRender.push(
        <Link to="/user" className="link-dark text-white">
          <MenuItem icon={<PeopleOutlinedIcon />}>{element}</MenuItem>
        </Link>
        // <a href={`${"/" + element.toLowerCase()}`} className="link-dark text-white">
        //   <MenuItem icon={<PeopleOutlinedIcon />}>{element}</MenuItem>
        // </a>
      );
    } else if (element === "PEMBELIAN") {
      menuListRender.push(
        <Link to="/pembelian" className="link-dark text-white">
          <MenuItem icon={<ShoppingBasketOutlinedIcon />}>{element}</MenuItem>
        </Link>

        // <a
        //   href={`${"/" + element.toLowerCase()}`}
        //   className="link-dark text-white"
        //   onClick={() => {
        //     navigate("/pembelian", {
        //       state: {
        //         listMenu: userMenu,
        //       },
        //     });
        //   }}
        // >
        //   <MenuItem icon={<ShoppingBasketOutlinedIcon />}>{element}</MenuItem>
        // </a>
      );
    } else if (element === "PENJUALAN") {
      menuListRender.push(
        <Link to="/penjualan" className="link-dark text-white">
          <MenuItem icon={<SellOutlinedIcon />}>{element}</MenuItem>
        </Link>
        // <a href={`${"/" + element.toLowerCase()}`} className="link-dark text-white">
        //   <MenuItem icon={<SellOutlinedIcon />}>{element}</MenuItem>
        // </a>
      );
    } else if (element === "STOK") {
      menuListRender.push(
        <Link to="/stok" className="link-dark text-white">
          <MenuItem icon={<Inventory2OutlinedIcon />}>{element}</MenuItem>
        </Link>

        // <a href={`${"/" + element.toLowerCase()}`} className="link-dark text-white">
        //   <MenuItem icon={<Inventory2OutlinedIcon />}>{element}</MenuItem>
        // </a>
      );
    } else {
      menuListRender.push(
        <Link to={`${"/" + element.toLowerCase()}`} className="link-dark text-white">
          <MenuItem icon={<WidgetsOutlinedIcon />}>{element.toLowerCase()}</MenuItem>
        </Link>

        // <a href={`${"/" + element.toLowerCase()}`} className="link-dark text-white">
        //   <MenuItem icon={<WidgetsOutlinedIcon />}>{element.toLowerCase()}</MenuItem>
        // </a>
      );
    }
  });

  userMenuMaster.forEach((element) => {
    if (element === "SUPPLIER") {
      masterListRender.push(
        <Link to="/supplier" className="link-dark text-white">
          <MenuItem icon={<LocalShippingOutlinedIcon />}>{element}</MenuItem>
        </Link>
      );
    } else if (element === "PAYMENT") {
      masterListRender.push(
        <Link to="/payment" className="link-dark text-white">
          <MenuItem icon={<PointOfSaleOutlinedIcon />}>{element}</MenuItem>
        </Link>
      );
    } else if (element === "METODE PEMBAYARAN") {
      masterListRender.push(
        <Link to="/paymentMethod" className="link-dark text-white">
          <MenuItem icon={<AddCardOutlinedIcon />}>{element}</MenuItem>
        </Link>
      );
    } else if (element === "BANK") {
      masterListRender.push(
        <Link to="/bank" className="link-dark text-white">
          <MenuItem icon={<AccountBalanceOutlinedIcon />}>{element}</MenuItem>
        </Link>
      );
    } else {
      masterListRender.push(
        <Link to={`${"" + element.toLowerCase}`} className="link-dark text-white">
          <MenuItem icon={<WidgetsOutlinedIcon />}>{element}</MenuItem>
        </Link>
      );
    }
  });

  return (
    <>
      <Sidebar style={{ height: "100vh" }} backgroundColor="#264653" className="text-white shadow">
        <Menu>
          <MenuItem
            icon={<MenuOutlinedIcon />}
            onClick={() => {
              collapseSidebar();
            }}
            style={{ textAlign: "center" }}
          >
            {" "}
            <h4>Point Of Sales</h4>
          </MenuItem>

          <Link to="/dashboard" className="link-dark text-white">
            <MenuItem icon={<HomeOutlinedIcon />}>Home</MenuItem>
          </Link>

          {/* <a
            href={`${"/dashboard"}`}
            className="link-dark text-white"
            onClick={() => {
              navigate("/dashboard", {
                state: {
                  listMenu: userMenu,
                },
              });
            }}
          >
            <MenuItem icon={<HomeOutlinedIcon />}>Home</MenuItem>
          </a> */}

          {menuListRender}

          <SubMenu
            icon={<SourceOutlinedIcon />}
            label="MASTER"
            className="link-dark text-white"
            rootStyles={{
              ["." + menuClasses.subMenuContent]: {
                backgroundColor: "#264653",
              },
            }}
          >
            {masterListRender}
          </SubMenu>

          <Link
            to="/"
            className="link-dark text-danger"
            onClick={() => {
              localStorage.clear();
            }}
          >
            <MenuItem icon={<LogoutOutlinedIcon />}>Logout</MenuItem>
          </Link>
        </Menu>
      </Sidebar>
    </>
  );
};

export default SideNavBar;
