import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { useContext } from "react";
import AddItemColor from "../../ItemColor/Add/AddItemColor";

const AddItem = () => {
  const addItemRef = useRef(null);
  const [msgResponse, setMsgResponse] = useState();
  const [msgError, setMsgError] = useState();
  const [isAddColorBtnClicked, setIsAddColorBtnClicked] = useState(false);
  // const [itemId, setItemId] = useState();
  const { itemId, setItemId } = useContext(GlobalContext);
  const [readOnly, setReadOnly] = useState(false);

  const handleSubmitItem = async (event) => {
    event.preventDefault();
    // setErrorMsg("");
    let request = {};
    const form = addItemRef.current;
    const data = new FormData(form);
    request["name"] = data.get("itemName");
    request["description"] = data.get("itemDescription");

    onSubmitNewItem(request);
  };

  const onSubmitNewItem = async (request) => {
    setReadOnly(false);
    await axios
      .post(`${process.env.REACT_APP_BASE_API_URL}/item`, request, {
        headers: {
          token: localStorage.getItem("uToken"),
        },
      })
      .then(function (response) {
        setMsgResponse(response.data.msg);
        setItemId(response.data.data.id);
        setReadOnly(true);
      })
      .catch(function (error) {
        console.log(error);
        setMsgError(error.data.msg);
        setReadOnly(false);
      });
  };

  return (
    <>
      <div className="card p-3 mb-3 bg-light">
        <h2 className="mb-5 text-center">Tambah Data Item</h2>
        <form ref={addItemRef} onSubmit={handleSubmitItem}>
          <div className="mb-3 row form-group">
            <label htmlFor="itemName" className="col-sm-3 col-form-label form-label">
              Nama Item
            </label>
            <div className="col-sm-4">
              <input type="text" class="form-control" name="itemName" placeholder="" disabled={readOnly} />
            </div>
          </div>

          <div className="mb-3 row form-group">
            <label htmlFor="itemDescription" className="col-sm-3 col-form-label form-label">
              Deskripsi
            </label>
            <div className="col-sm-8">
              <input type="text" class="form-control" name="itemDescription" placeholder="" disabled={readOnly} />
            </div>
          </div>

          {msgResponse != null && (
            <>
              <div className="text-center">
                {!isAddColorBtnClicked ? <p className="text-success">item berhasil ditambahkan, click button berikut untuk menambahkan warna</p> : ""}
                <button
                  type="button"
                  className="btn btn-primary mt-2 mx-auto"
                  onClick={() => {
                    setIsAddColorBtnClicked(!isAddColorBtnClicked);
                  }}
                  disabled={isAddColorBtnClicked}
                >
                  Tambah Warna Item
                </button>
              </div>
            </>
          )}

          {isAddColorBtnClicked && <AddItemColor previousPage="AddItem" />}

          {msgError != null && (
            <>
              <p className="text-danger">{msgError}</p>
            </>
          )}

          {msgResponse == null && (
            <div className="text-center">
              <button type="button" className="btn btn-primary mt-2 " onClick={handleSubmitItem}>
                Tambah
              </button>
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default AddItem;
